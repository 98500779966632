import { type SVGProps } from "react";
import href from "./icon.svg";
export { href };

export default function Icon({ icon, ...props}: SVGProps<SVGSVGElement> & { icon: IconName }) {
  return (
    <svg {...props}>
      <use href={`${href}#${icon}`} />
    </svg>
  );
}

export const iconNames = [
  "bell",
  "bundle",
  "check",
  "checkmark",
  "chevronDown",
  "close",
  "collection",
  "dash",
  "downArrow",
  "eye",
  "filter",
  "flag",
  "grid",
  "heart",
  "lighting",
  "loader",
  "lootbox",
  "message",
  "people",
  "personPlus",
  "plus",
  "ringingBell",
  "search",
  "send",
  "star",
  "stopSign",
  "thumbsDown",
  "thumbsUp",
  "unequip",
  "verified",
] as const;
export type IconName = typeof iconNames[number];

export const BellIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="bell" {...props} />;
export const BundleIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="bundle" {...props} />;
export const CheckIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="check" {...props} />;
export const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="checkmark" {...props} />;
export const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="chevronDown" {...props} />;
export const CloseIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="close" {...props} />;
export const CollectionIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="collection" {...props} />;
export const DashIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="dash" {...props} />;
export const DownArrowIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="downArrow" {...props} />;
export const EyeIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="eye" {...props} />;
export const FilterIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="filter" {...props} />;
export const FlagIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="flag" {...props} />;
export const GridIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="grid" {...props} />;
export const HeartIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="heart" {...props} />;
export const LightingIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="lighting" {...props} />;
export const LoaderIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="loader" {...props} />;
export const LootboxIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="lootbox" {...props} />;
export const MessageIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="message" {...props} />;
export const PeopleIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="people" {...props} />;
export const PersonPlusIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="personPlus" {...props} />;
export const PlusIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="plus" {...props} />;
export const RingingBellIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="ringingBell" {...props} />;
export const SearchIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="search" {...props} />;
export const SendIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="send" {...props} />;
export const StarIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="star" {...props} />;
export const StopSignIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="stopSign" {...props} />;
export const ThumbsDownIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="thumbsDown" {...props} />;
export const ThumbsUpIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="thumbsUp" {...props} />;
export const UnequipIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="unequip" {...props} />;
export const VerifiedIcon = (props: SVGProps<SVGSVGElement>) => <Icon icon="verified" {...props} />;